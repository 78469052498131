<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="7.50009" r="7" stroke="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCircleFilled'
}
</script>
